import { Vue, Component } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Action, Getter, State as StateClass } from 'vuex-class';
import { db } from '@/firebase';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import BigNumber from 'bignumber.js';
import { State } from '@/store/models';
import { IdentRouteNames } from '@/modules/identification/identRouteNames';
import useClipboard from 'vue-clipboard3';
import { Referral } from '@/store/models/investment';

@Component({
  components: {
    FormInput,
    ValidationProvider,
    ValidationObserver,
  },
})

export default class ReferralCodeModal extends Vue {
  referralObject: { [key: string]: any } = {};
  boundReferral: undefined | Function;
  referral: Referral = {} as Referral;

  IdentRouteNames = IdentRouteNames

  @StateClass user!: State['user']

  @Action(addToastMessage) addToastMessage!: Function;

  @Getter isEligibleToInvest!: Function;

  async mounted(): Promise<void> {
    this.$modal.show('referralCodeModal');

    await this.fetchReferralData();
  }

  async fetchReferralData(): Promise<void> {
    const investorRef = db.collection('investors').doc(this.user?.id);
    const querySnapshot = await db.collection('referral')
      .where('investor', '==', investorRef)
      .where('deleted', '==', false)
      .get();

    if (!querySnapshot.empty) {
      this.referral = querySnapshot.docs[0].data() as Referral;
    }
  }

  get termsAndConditions(): string {
    return this.$route.params.lang === 'en'
      ? 'https://corekees.com/wp-content/uploads/2024/01/Promotion-terms-Corekees-referral-program-ENG-.pdf'
      : 'https://corekees.com/wp-content/uploads/2024/01/Actievoorwaarden-Corekees-referral-programma-NL.pdf';
  }

  get referralCode(): string {
    return this.referral?.code;
  }

  get totalRedeemed(): number {
    return new BigNumber(this.referral.totalRedeemed as number).toNumber() || 0;
  }

  get totalRedeemedFriends(): number {
    const totalRedeemed = new BigNumber(this.referral.totalRedeemed as number).dividedBy(50).toNumber() || 0;
    return Math.round(totalRedeemed);
  }

  async copyReferralCode(): Promise<void> {
    const { toClipboard } = useClipboard();
    try {
      await toClipboard(this.referralCode);
      this.addToastMessage({
        text: this.$t('referralCode.copySuccess'),
        type: 'success',
      });
    } catch (e) {
      console.log(e); // eslint-disable-line
    }
  }

  get treeContentImage(): string {
    return require('@/assets/icons/icon-tree.anim.svg') || '';
  }
}
