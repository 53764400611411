import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, Getter, State as StateClass } from 'vuex-class';
import { Slide } from 'vue-burger-menu';
import { State } from '@/store/models';
import { db } from '@/firebase';
import Logo from '@/components/common/logo/Logo.vue';
import NavbarLinks from '@/components/common/navbar/links/NavbarLinks.vue';
import DropdownMenu from '@/components/common/navbar/dropdown/DropdownMenu.vue';
import LanguagesAccount from '@/components/common/navbar/languages-account/button/LanguagesAccount.vue';
import LoginRegister from '@/components/common/navbar/login-register/LoginRegister.vue';
import ResponsiveMenu from '@/components/common/navbar/responsive/ResponsiveMenu.vue';
import ResponsiveLinks from '@/components/common/navbar/responsive/links/ResponsiveLinks.vue';
import isAuthPageHelper from '@/helpers/isAuthPageHelper';
import ReferralCodeModal from '@/components/account/referral-code/ReferralCodeModal.vue';

@Component({
  components: {
    Slide,
    Logo,
    NavbarLinks,
    DropdownMenu,
    ResponsiveLinks,
    LanguagesAccount,
    LoginRegister,
    ResponsiveMenu,
    ReferralCodeModal,
  },
})

export default class Navbar extends Vue {
  @StateClass auth!: State['auth'];
  @StateClass misc!: State['misc'];
  @StateClass authModal!: State['authModal'];
  @StateClass user!: State['user']
  @Getter isUserLoggedIn!: State['isUserLoggedIn'];
  @Getter isModalOpen!: boolean;
  @Getter getSharesTotalInvested!: number;
  @Action openModal!: Function;

  isSliderOpen: boolean = false;
  showReferralCodeModal = false;
  referralCode: string | null = null;

  @Watch('user.id')
  async onUserIdChange(newId: string): Promise<void> {
    if (newId) {
      await this.fetchReferralCode();
      if (this.referralCode) {
        this.showReferralCodeModal = true;
        this.$modal.show('referralCodeModal');
      }
    }
  }

  @Watch('isModalOpen')
  onAuthActiveChange(isModalOpen): void {
    if (isModalOpen) {
      this.isSliderOpen = true;
      setTimeout((): void => {
        this.isSliderOpen = false;
      }, 150);
    }
  }

  async fetchReferralCode(): Promise<void> {
    const investorRef = db.collection('investors').doc(this.user?.id);
    const querySnapshot = await db.collection('referral')
      .where('investor', '==', investorRef)
      .where('deleted', '==', false)
      .get();

    if (!querySnapshot.empty) {
      this.referralCode = querySnapshot.docs[0].data().code as string;
    }
  }

  get showModal(): boolean | null {
    return this.isUserLoggedIn && this.showReferralCodeModal;
  }

  get brand(): string {
    return require('../../../../whitelabel.config').name;
  }

  /* get myBrand(): string {
    return `${capitalize(this.$t('common.my') as string)} ${capitalize(this.brand)}`;
  } */

  get isLanding(): boolean {
    return this.$route.fullPath.startsWith('/landing');
  }

  get isAuthPage(): boolean {
    return isAuthPageHelper(this.$route);
  }

  get lang(): string {
    return this.$route.params.lang;
  }

  get showReferralModalButton(): boolean {
    const fullPath = this.$route.fullPath;
    return this.getSharesTotalInvested >= 1 && /\/account\/?$/.test(fullPath);
  }

  toggleReferralCodeModal(): void {
    this.showReferralCodeModal = !this.showReferralCodeModal;
  }
}
