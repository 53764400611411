import { render, staticRenderFns } from "./ReferralCodeModal.html?vue&type=template&id=630a6642&scoped=true&"
import script from "./ReferralCodeModal.ts?vue&type=script&lang=ts&"
export * from "./ReferralCodeModal.ts?vue&type=script&lang=ts&"
import style0 from "./ReferralCodeModal.scss?vue&type=style&index=0&id=630a6642&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630a6642",
  null
  
)

export default component.exports